const HavaDurumu = (props) => {
    const {weather} = props

    if(!weather) {
        return <p> { weather ? "Yükleniyor..." : "Lütfen Konumunuzun Açık Olduğundan Emin Olun"}</p>
    }

    return(
        <div>
            <h3> {"Şehir İsmi => " + weather.name} </h3>
            <h4> {"Havanın Tarzı => " +  weather.weather.map(data => data.description).join(", ")} </h4>
            <p> {"Hava Sıcaklık Derecesi => " + weather.main.temp} C </p>
            <p>{"O Anki Tarih => " + new Date(weather.dt * 1000).toLocaleDateString()}</p>
        </div>
    )
}

export default HavaDurumu